.nav {
    background: var(--colorBackgroundMain);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 500;
}

.nav__container {
    margin: auto;
    /*max-width: 1200px;*/
    height: 6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav__header {
    display: flex;
    align-items: center;
    text-decoration: none;
}

.nav__headerImg {
    height: 4rem;
    width: auto;
    padding: 3rem
}

.nav_userInfo{
    display: flex;
    align-items: center;
    margin-right: 2rem;
}


.nav_userInfo_text{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}


.nav_userInfo_name{
    color: #32343A;
}

.nav_userInfo_email{
    color: #7A7D85;
}

.nav_userInfo_circle{
    margin-left: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 10px;
    width: 32px;
    height: 32px;
    background: #F2F2F3;
    border-radius: 100px;
    color: #7A7D85;
    font-size: 10px;
}