.ResetPassword{
    display: flex;
    align-content: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.ResetPassword_container{
    min-width: 328px;
    max-width: 350px;
    display: flex;
    flex-direction: column;
}


.ResetPassword_title{
    margin: 3rem 0rem;
}


.ResetPassword_back_btn{
    cursor: pointer;
    display: flex;
}

.ResetPassword_description{
    margin-bottom: 1rem;
}