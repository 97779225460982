.DeleteAccountModal {
    z-index: 501;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.15);
}


.DeleteAccountModal_container {
    position: relative;
    width: 454px;
    max-width: 25rem;
    background-color: white;
    padding: 1.5rem;
    margin: 5rem 1rem 1rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transform: translateY(-2rem);
    opacity: 0;
    animation: showAlert 0.5s ease-out both;
}



@keyframes showAlert {
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.DeleteAccountModal__closeSVG {
    position: absolute;
    top: .5rem;
    right: .5rem;
    cursor: pointer;
    z-index: 10;
}

.DeleteAccountModal_content {
    width: 100%;
    height: 100%;
}


.DeleteAccountModal_content_chart_transform {
    height: calc(100vh - 8rem) !important;
}


.DeleteAccountModal__topPart {
    padding: 0 0 1rem 0;
    display: flex;
    flex-direction: column;
}

.DeleteAccountModal__topPart_textContainer {
    margin: 0 1rem 0 0;
}



.DeleteAccountModal__middlePart {
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
}

.DeleteAccountModal__input {
    width: 100%;
    font-size: 1rem;
    margin-top: 0.5rem;
    padding: 0.5rem;
    outline: none;
    border: var(--colorBright) solid 0.1rem;
}


.DeleteAccountModal__btnContainer {
    padding: 3rem 0 0 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.DeleteAccountModal__text {
    font-size: 1rem;
    line-height: 1.5rem;
    margin-right: 2rem;
}


.DeleteAccountModal__warnPart{
    color: #EA280A;
    margin-top: 1rem;
    margin-bottom: 1rem;
}