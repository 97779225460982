.footer {
    width: 100%;
    background-color: var(--colorBackgroundMain);
  }
  
  .footer__container {
    padding: 2rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .footer__top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
  }

  .footer__bottom_privacy {
    /* height: 50px; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 2rem;
    border-top: var(--colorBright) 0.1rem solid;
  }

  .footer__bottom_privacy_copyright {
    font-size: 0.7rem;
  }
