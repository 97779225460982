
.ProfileTab{
    padding-top: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
}

.ProfileTab_divider{
    border-bottom: 1px solid var(--colorSuperBright);
    margin-bottom: 1rem;
}

.ProfileTab_head_title{
    font-size: 26px;
}

.ProfileTab_head_double{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ProfileTab_head_right{
    display: flex;
    align-items: center;
}

.ProfileTab_head_description{
    margin-top: 8px;
    margin-bottom: 2rem;
    font-size: 16px;
    color: #7A7D85;
}


.inputColumn{
    max-width: 320px;
    min-width: 310px;
}

/* Password*/

.Password_buttons{
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-content: center;
}


.ProfileTab_head_right .btn__transparent_underline{
    margin-right: 2rem;
}


.Password_editSection{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-content: flex-start;
    align-items: flex-start;
    margin-top: 2rem;
}



.Password_forgotPassword_container {
    font-size: 100%;
    box-sizing: border-box;
    color: var(--colorMainDark);
    font-family: AeonikPro-Regular, Arial, Helvetica, sans-serif;
    margin: 0;
    background: var(--colorBackgroundBright);
    padding: 2rem;
    max-width: 400px;
    height: fit-content;
    margin-right: 2rem;
}

.Password_forgotPassword_title {
    font-size: 100%;
    box-sizing: border-box;
    color: var(--colorMainDark);
    font-family: AeonikPro-Regular, Arial, Helvetica, sans-serif;
    margin: 0;
    padding: 0;
}

.Password_forgotPassword_text{
    line-height: 1.2rem;
    padding: 1rem 0;
}

.Password_forgotPassword_btn{
    box-sizing: border-box;
    font-family: AeonikPro-Regular,Arial,Helvetica,sans-serif;
    margin: 0;
    background-color: transparent;
    border: .1rem solid var(--colorMainDark);
    border-radius: 0;
    color: var(--colorMainDark);
    cursor: pointer;
    font-size: 1rem;
    padding: .5rem 1.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}



/* Profile*/

.ProfileDetails_section_title{
    font-size: 24px;
    padding-top: 2rem;
    padding-bottom: 1rem;
}


/* Application*/
.Application_warning{
    padding: 2rem;
    background: #FDECEE;
    margin-bottom: 2rem;
}

.Application_warning div{
    color: #C42008;
}

.Application_warning_title{
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 1rem;
}

.Application_warning_description{
    font-size: 13px;
    margin-bottom: 1rem;
}

.Application_warning_bottomline{
    font-weight: 700;
    font-size: 13px;
}

.Application_app_tiles_container{
    display: flex;
    flex-wrap: wrap;
}

.Application_app_tile{
    height: 270px;
    width: 320px;
    margin: 2rem;
    border: 1px solid var(--colorVeryBright);
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.Application_app_tile_title{
    color: var(--colorMainDark);
    font-weight: 500;
}

.Application_app_tile_description{
    font-size: 13px;
    margin-top: -3rem;
}

.Application_app_tile_icon img{
    height: 36px;
    width: 36px;
}

.Application_app_icon_title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
    align-items: center;
}

.ProfileTab_delete_profile .ProfileTab_head_title{
    margin: 1rem 0rem;

}
