@font-face {
  font-family: 'AeonikPro-Bold';
  src: url('assets/fonts/AeonikPro-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'AeonikPro-Medium';
  src: url('assets/fonts/AeonikPro-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'AeonikPro-Regular';
  src: url('assets/fonts/AeonikPro-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'AeonikPro-Light';
  src: url('assets/fonts/AeonikPro-Light.otf') format('opentype');
}



*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  font-family: 'AeonikPro-Regular', Arial, Helvetica, sans-serif;
  color: var(--colorMainDark);
}


html,
body {
  font-size: 100%;  /* rem = 16px */
  overflow-x: hidden;
  color: var(--colorMainDark);
}

:root {
  --colorBackgroundMain: white;
  --colorMainDark: #32343A; /* e.g. call: var(--colorMainDark) */
  --colorMainBright: #484A51;
  --colorBackgroundBright: #F2F2F3;
  --colorBright: #CBCBCD;
  --colorVeryBright: #B0B1B5;
  --colorSuperBright: #E5E5E6;
  --colorOrange: #FF4F00;
  --colorError: #EA280A;
  --colorDark: #61636b;
  --colorSemiDark: #7a7d85;
}

/* remove arrows from input (Chrome, Safari, Edge, Opera) */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* remove arrows from input (Firefox) */
input[type=number] {
  -moz-appearance: textfield;
}

/* spinner for lazy loading */
.fallback__spinner {
  width: 100%;
  height: calc(100vh - 13rem);
  position: relative;
}

/* --------------- GENERAL --------------- */



/* page*/
.page{
  position: relative;
  min-height: calc(100vh - 5rem);
  padding-top: 6rem;
  width: 100%;
  max-width: 1200px;
  margin: auto;
}


/* img */
.img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

/* text */
.text__bold {
  font-weight: bold;
}

/* buttons */

.btn__light {
  background-color: transparent;
  padding: 0;
  font-size: 1rem;
  color: var(--colorMainDark);
  border: none;
  cursor: pointer;
  text-decoration: underline;
  white-space: nowrap;
}

.btn__light:disabled  {
  color: var(--colorVeryBright);
  cursor: default;
}

.btn__medium {
  background-color: transparent;
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
  color: var(--colorMainDark);
  border: var(--colorMainDark) solid 0.1rem;
  border-radius: 0;
  cursor: pointer;
  white-space: nowrap;
}

.btn__medium:active {
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
}

.btn__hard{
  background-color: var(--colorMainDark);
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
  color: white;
  border: none;
  border-radius: 0;
  cursor: pointer;
  white-space: nowrap;
}


.btn__hard_inverted{
  padding: 0.5rem 1.5rem;
  background: var(--colorBackgroundMain);
  font-size: 1rem;
  color: var(--colorMainDark);
  border: 1px solid var(--colorMainDark);
  border-radius: 0;
  cursor: pointer;
  white-space: nowrap;
}

.btn__transparent_underline{
  text-decoration: underline;
  cursor: pointer;
}

.btn__hard:disabled {
  background-color: var(--colorVeryBright);
  cursor: default;
}

.btn__hard:active {
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
}

.btn__delete_soft {
  background: none;
  border: none;
  color: var(--colorMainDark);
  text-decoration: underline;
  text-underline-offset: .2rem;
  text-decoration-color: #9da1af;
  opacity: 75%;
  cursor: pointer;
}

.btn__delete_soft:hover {
  opacity: 100%;
  color: var(--colorError);
  text-decoration-color: var(--colorError);
  opacity: 90%;
  transition: all;
  transition-duration: .3s;
}

/* inputs */

input[type=checkbox] {
  position: relative;
  cursor: pointer;
  width: 0px;
  height: 0px;
  margin-right: 16px;
  background: white;
  border-radius: 2px;
}


input[type=checkbox]:after {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  border: solid 1px var(--colorMainDark);
  border-radius: 2px;
  background: white;
}


.invalid[type=checkbox]:after {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  border: solid 1px var(--colorError);
  border-radius: 2px;
}

input[type=checkbox]:checked:before {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  background-color: #32343A;
  border: solid 3px white;
  border-radius: 2px;
}

input[type=checkbox]:checked:after {
  background-color: unset;
}

input[type=checkbox]:disabled:before {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  background-color: var(--colorMainDark);
  border: solid 3px white;
  border-radius: 2px;
}

.checkbox__container {
  display: flex;
  align-items: flex-start;
}

.checkbox__outer_container{
  margin-bottom: 1rem;
}

.checkbox__label{
  margin-left: 0.8rem;
}


.checkbox__show_description_button{
  text-decoration: underline;
  cursor: pointer;
  padding: 1rem 2.3rem;
}

.checkbox__long_description{
  padding-left: 1.8rem;
}

/* text */

.text__small {
  font-size: 0.8rem;
}

.text__bold {
  font-family: 'AeonikPro-Bold', Arial, Helvetica, sans-serif;
  font-weight: bold;
}

.text__medium {
  font-family: 'AeonikPro-Medium', Arial, Helvetica, sans-serif;
}

.text__regular { /* default */
  font-family: 'AeonikPro-Regular', Arial, Helvetica, sans-serif;
  font-weight: normal;
}


.text__light {
  font-family: 'AeonikPro-Light', Arial, Helvetica, sans-serif;
  font-weight: lighter;
}




.link__underline{
  text-decoration: underline;
  cursor: pointer;
}


.aic_input {
  display: flex;
  flex-direction: column;
}

.aic_input_label {
  font-size: 1rem;
  display: flex;
  margin-bottom: 0.5rem;
}

.aic_input_label_optional{
  margin-left: 0.5rem;
  font-size: 0.8rem;
}

.aic_input_input {
  min-width: 16rem;
  font-size: 1rem;
  padding: 0.5rem;
  outline: none;
  background: var(--colorBackgroundMain);
  border: var(--colorBright) solid 0.1rem;
}



.checkbox__outer_container .aic_input_error_msg{
  margin-left: 20px;
}

.aic_input_error_msg{
  color: var(--colorError);
  pointer-events: none;
  font-size: 0.7rem;
  margin-top: -1rem;
  margin-left: 1px;

  z-index: -1;
  transition: margin 700ms ease-in-out;
  opacity: 0;
}

.aic_input_error_msg.show_error{
  margin-top: 4px;
  margin-left: 1px;
  opacity: 1;
}

.invalid{
  border: 1px solid var(--colorError);
}

