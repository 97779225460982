

.Profile{
    display: flex;
    border-top: 1px solid var(--colorSuperBright);
    position: relative;
    min-height: calc(100vh - 5rem);
    padding-top: 8rem;
    width: 100%;
}


.Profile_tab_container{
    width: 100%;
    border-top: 1px solid var(--colorSuperBright);
}

.Profile_menu{
    border: 1px solid var(--colorSuperBright);
    border-bottom: 0px;
    padding-top: 2rem;

}


.Profile_menu_divider{
    border-bottom: 1px solid var(--colorSuperBright);

}


.Profile_menu_item{
    height: 52px;
    width: 264px;
    left: 8px;
    top: 0px;
    padding: 16px;
    font-size: 16px;
    display: flex;
    color: #7A7D85;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    margin: 8px;
}

.Profile_menu_item:hover, .active_tab{
    background: #F2F2F3;
    color: #32343A;
    cursor: pointer;
}

.Profile_menu_item_icon{
    margin-right: 1rem;
}

.Profile_menu_item_icon path{
    fill: #7A7D85;
    stroke-width: 1px;
}




