.Register{
    display: flex;
    align-content: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.Register_container{
    min-width: 328px;
    max-width: 350px;
    display: flex;
    flex-direction: column;
}


.Register_welcome_title{
    margin: 3rem 0rem;
}


.Register_form{
    display: flex;
    flex-direction: column;
}


form .aic_input{
    margin-bottom: 1.8rem;
}

.Register_buttons button{
    margin-top: 1rem;
    width: 100%;
}

.Register_success{
    margin-bottom: 1rem;
}