.spinner_ring {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner_ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 5rem;
  height: 5rem;
  padding: 1rem;
  border: 0.5rem solid #fff;
  border-radius: 50%;
  animation: spinner_ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--colorMainDark) transparent transparent transparent;
}

.spinner_ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.spinner_ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.spinner_ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes spinner_ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
