

.Login{
    display: flex;
    align-content: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.Login_container{
    min-width: 328px;
    max-width: 350px;
    display: flex;
    flex-direction: column;
}


.Login_form{
    display: flex;
    flex-direction: column;
}


form .aic_input{
    margin-bottom: 1.8rem;
}


.remember_me_and_forget_password{
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
}

.separator{
    border-bottom: 1px solid var(--colorBright);
    width: 100%;
    margin: 2rem 0rem;
}

.Login_welcome_msg{
    padding-bottom: 2rem;
}


