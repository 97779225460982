.UserConfirmed{
    min-width: 328px;
    max-width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.UserConfirmed_container{
    display: flex;
    align-content: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}


.UserConfirmed_success{
    margin-bottom: 1rem;
}