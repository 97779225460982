.reactAlert {
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
}

.reactAlert__container {
    display: flex;
    align-items: center;
}

.reactAlert__svg {
    margin: 0 0.8rem 0 0;
    width: 1rem;
    height: 1rem;
}

.reactAlert__text {
    flex: 1;
}

.reactAlert__btn {
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
}

@media (min-width: 500px) {
    .reactAlert {
        width: 90vw;
    }
}

@media (min-width: 1000px) {
    .reactAlert {
        width: 80vw;
    }
}